import React from "react";
import {Stack, Grid, Image, Text, Icon} from "@chakra-ui/core";
import Link from "@pency/components/controls/Link";

import Heading from "~/ui/display/Heading";
import {Tenant} from "~/tenant/types";
import {useTranslation} from "~/i18n/hooks";
import { COLUMNS } from "~/constanst/home";

interface Props {
  tenants: Tenant[];
}

const Testimonials: React.FC<Props> = () => {
  const t = useTranslation();

  return (
    <Stack
      paddingX={{base: 0}}
      paddingY={{base: 12, md: 20}}
      textAlign={{base: "center", md: "left"}}
    >
      <Stack
        paddingX={{base: 4, md: 16}}
        paddingY={{base: 2, md: 4}}
        spacing={4}
        width={'60%'}
        marginX={{base: "auto", md: "inherit"}}
      >
        <Heading as="h2" fontSize={{base: "3xl", md: "5xl"}} color={"green.400"}>
          {t("home.testimonials.title")}
        </Heading>
      </Stack>
      <Grid
        alignItems="flex-start"
        gridAutoFlow="column"
        gridGap={{base: 4, sm: 8}}
        overflowX="auto"
        overflowY="hidden"
        paddingBottom={10}
        paddingTop={6}
        style={{scrollSnapType: "x mandatory"}}
      >
        <Stack style={{scrollSnapAlign: "start"}} width={{base: 1, md: 4}} />
        {COLUMNS(t).map((testimonial) => (
          <Stack
            key={testimonial.id}
            backgroundColor="white"
            borderRadius="lg"
            boxShadow="xl"
            padding={8}
            spacing={6}
            style={{scrollSnapAlign: "start"}}
            textAlign="left"
            width={{base: "320px", md: "320px"}}
            height={{base: "425px", md: "425px"}}
            justify={"space-between"}
          >
            <Text fontSize={{base: "lg", md: "xl"}}>{testimonial.text}</Text>
            <Stack isInline alignItems="center" spacing={4}>
              <Image
                borderRadius="50%"
                height={16}
                src={`/assets/${testimonial.store.slug}.jpg`}
                width={16}
              />
              <Stack spacing={0}>
                <Text fontSize={{base: "md"}} fontWeight="700">
                  {testimonial.name}
                </Text>
                <Link
                  isExternal
                  _hover={{color: "primary.900"}}
                  color="primary.300"
                  href={testimonial.store.href}
                >
                  {testimonial.store.name}
                  <Icon color="primary.300" marginLeft={1} name="link" size="3" />
                </Link>
              </Stack>
            </Stack>
          </Stack>
        ))}
        <Stack style={{scrollSnapAlign: "start"}} width={{base: 1, md: 4}}>
          <Text color="yellow.50" fontSize={{base: "lg", md: "xl"}}>
            0
          </Text>
        </Stack>
      </Grid>
    </Stack>
  );
};

export default Testimonials;
