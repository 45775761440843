import Text from "@chakra-ui/core/dist/Text";

export const FOR_CLIENTS = {
  title: "home.madeForClients.title",
  subtitle: "home.madeForClients.subtitle",
  img: "/assets/home-circle.png",
  bulletPoints: [
    "home.madeForClients.items.fast",
    "home.madeForClients.items.simple",
    "home.madeForClients.items.forall",
    "home.madeForClients.items.noapp"
  ],
  button: "home.madeForClients.button"
};

export const FAQS = (t) => [
    {
        id: "whatIs",
        title: t("home.faqs.questions.one.question"),
        description: t("home.faqs.questions.one.answer"),
    },
    {
        id: "needs",
        title: t("home.faqs.questions.two.question"),
        description: t("home.faqs.questions.two.answer"),
    },
    {
        id: "free",
        title: t("home.faqs.questions.three.question"),
        description: t("home.faqs.questions.three.answer"),
    },
    {
        id: "mobil",
        title: t("home.faqs.questions.four.question"),
        description: t("home.faqs.questions.four.answer"),
    },
    {
        id: "services",
        title: t("home.faqs.questions.five.question"),
        description: t("home.faqs.questions.five.answer"),
    },
    {
        id: "admin",
        title: t("home.faqs.questions.sixth.question"),
        description: t("home.faqs.questions.sixth.answer"),
    },
    {
        id: "country",
        title: t("home.faqs.questions.seventh.question"),
        description: t("home.faqs.questions.seventh.answer"),
    },
    {
        id: "cancel",
        title: t("home.faqs.questions.eighth.question"),
        description: t("home.faqs.questions.eighth.answer"),
    },
];

export const COLUMNS = (t) => [
    {
      id: "justi",
      name: t("home.testimonials.customers.one.name"),
      store: {
        name: t("home.testimonials.customers.one.store.name"),
        slug: t("home.testimonials.customers.one.store.slug"),
        href: `https://blondies.now.sh/tienda`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.one.quote")}</Text>
      ),
    },
    {
      id: "organization",
      name: t("home.testimonials.customers.two.name"),
      store: {
        name: t("home.testimonials.customers.two.store.name"),
        slug: t("home.testimonials.customers.two.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.two.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.two.quote")}</Text>
      ),
    },
    {
      id: "practic",
      name: t("home.testimonials.customers.three.name"),
      store: {
        name: t("home.testimonials.customers.three.store.name"),
        slug: t("home.testimonials.customers.three.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.three.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.three.quote")}</Text>
      ),
    },
    {
      id: "ui",
      name: t("home.testimonials.customers.four.name"),
      store: {
        name: t("home.testimonials.customers.four.store.name"),
        slug: t("home.testimonials.customers.four.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.four.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.four.quote")}</Text>
      ),
    },
    {
      id: "free",
      name: t("home.testimonials.customers.five.name"),
      store: {
        name: t("home.testimonials.customers.five.store.name"),
        slug: t("home.testimonials.customers.five.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.five.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.five.quote")}</Text>
      ),
    },
    {
      id: "more-sells",
      name: t("home.testimonials.customers.six.name"),
      store: {
        name: t("home.testimonials.customers.six.store.name"),
        slug: t("home.testimonials.customers.six.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.six.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.six.quote")}</Text>
      ),
    },
    {
      id: "easy",
      name: t("home.testimonials.customers.seven.name"),
      store: {
        name: t("home.testimonials.customers.seven.store.name"),
        slug: t("home.testimonials.customers.seven.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.seven.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.seven.quote")}</Text>
      ),
    },
    {
      id: "catalog",
      name: t("home.testimonials.customers.eigth.name"),
      store: {
        name: t("home.testimonials.customers.eigth.store.name"),
        slug: t("home.testimonials.customers.eigth.store.slug"),
        href: `${process.env.STORE_URL}/${t("home.testimonials.customers.eigth.store.slug")}`,
      },
      text: (
        <Text fontSize={{base: "lg", md: "xl"}}>{t("home.testimonials.customers.eigth.quote")}</Text>
      ),
    },
];

export const FEATURES = [
  {
    title: "home.features.items.easy.title",
    description: "home.features.items.easy.description" ,
    icon: "/assets/homeFeatures/01.svg",
    alt: "home.features.items.easy.alt"
  },
  {
    title: "home.features.items.options.title",
    description: "home.features.items.options.description" ,
    icon: "/assets/homeFeatures/02.svg",
    alt: "home.features.items.options.alt"
  },
  {
    title: "home.features.items.brand.title",
    description: "home.features.items.brand.description" ,
    icon: "/assets/homeFeatures/03.svg",
    alt: "home.features.items.brand.alt"
  },
  {
    title: "home.features.items.data.title",
    description: "home.features.items.data.description" ,
    icon: "/assets/homeFeatures/04.svg",
    alt: "home.features.items.data.alt"
  },
  {
    title: "home.features.items.payments.title",
    description: "home.features.items.payments.description" ,
    icon: "/assets/homeFeatures/05.svg",
    alt: "home.features.items.payments.alt"
  },
  {
    title: "home.features.items.numbers.title",
    description: "home.features.items.numbers.description" ,
    icon: "/assets/homeFeatures/06.svg",
    alt: "home.features.items.numbers.alt"
  }
];

export const BENEFITS = [
  {
    title: "home.benefits.items.time.title",
    description: "home.benefits.items.time.description" ,
    image: "/assets/homeBenefits/01.svg",
    alt: "home.benefits.items.time.alt"
  },
  {
    title: "home.benefits.items.control.title",
    description: "home.benefits.items.control.description" ,
    image: "/assets/homeBenefits/02.svg",
    alt: "home.benefits.items.control.alt"
  },
  {
    title: "home.benefits.items.reach.title",
    description: "home.benefits.items.reach.description" ,
    image: "/assets/homeBenefits/03.svg",
    alt: "home.benefits.items.reach.alt"
  },
];