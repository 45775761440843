import React from "react";
import {Image, Stack, Text} from "@chakra-ui/core";

import {useTranslation} from "~/i18n/hooks";
import { Feature } from "~/types/features";

interface Props {
  feature: Feature;
}

const FeatureCard: React.FC<Props> = ({ feature }) => {
  const t = useTranslation();
  
  return (
      <Stack spacing={{ base: 2, md: 3 }}>
            <Image width={70} src={feature.icon} alt={t(feature.alt)} />
          <Text fontWeight="bold">
            {t(feature.title)}
          </Text>
          <Text>
            {t(feature.description)}
          </Text>
      </Stack>
  );
};

export default FeatureCard;