import React from "react";
import { Stack, Text, Image, Flex } from "@chakra-ui/core";
import Button from "@pency/components/controls/Button";
import Link from "@pency/components/controls/Link";

import Heading from "~/ui/display/Heading";
import Content from "~/ui/structure/Content";
import { getLocale } from "~/i18n/selectors";
import { useTranslation } from "~/i18n/hooks";

const CreateAccountCTA: React.FC<{display?: object, title: string, titleColor?: string, subtitle: string, btnText: string, svg1: string, svg2: string}> = 
  ({ display, title, titleColor, subtitle, btnText, svg1, svg2}) => {
  const locale = getLocale();
  const t = useTranslation();

  return (
    <Content paddingY={16} backgroundColor="primary.100" position={"relative"} display={display}>
      <Image
        flex={1}
        margin="auto"
        maxWidth={{ base: 96, md: 200 }}
        src={svg1}
        position={"absolute"}
        bottom={30}
        left={30}
      />
      <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }} justifyContent="center" >
        <Stack
          alignItems="center"
          justifyContent="center"
          maxWidth='75%'
          spacing={10}
          textAlign="center"
        >
          <Stack spacing={6}>
            <Heading as="h2" fontSize={{ base: "4xl", md: "5xl" }} color={titleColor || "inherit"}>
              {t(title)}
            </Heading>
            <Text fontSize={{ base: "lg", md: "xl" }}>{t(subtitle)}</Text>
          </Stack>
          <Link
            display={{ base: "none", md: "inherit" }}
            href={`${process.env.SIGNUP_URL}/${locale}`}
          >
            <Button _focus={{border: "0px"}} _hover={{ boxShadow: "lg" }} size="lg" color={"white"} backgroundColor="green.400">
              {t(btnText)}
            </Button>
          </Link>
        </Stack>
      </Flex>
      <Image
        display={{ base: "none", md: "inherit" }}
        flex={1}
        margin="auto"
        maxWidth={{ base: 128, md: 200 }}
        src={svg2}
        position={"absolute"}
        top={30}
        right={30}
      />
    </Content>
  );
};

export default CreateAccountCTA;
