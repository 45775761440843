import React from "react";
import {Stack, Image, Text, SimpleGrid, Box, Button, Flex} from "@chakra-ui/core";

import Heading from "~/ui/display/Heading";
import Content from "~/ui/structure/Content";
import {useTranslation} from "~/i18n/hooks";
import CheckIcon from "@pency/components/icons/CheckCircle";
import { FOR_CLIENTS } from "~/constanst/home";
import { formatTranslationText } from "~/utils/formatTranslations";
import TranslationsFormatText from "~/components/TranslationsFormatText";

const MadeForClients: React.FC = () => {
  const t = useTranslation();

  return (
    <Content my={{md: 24, base: 0}}>
      <SimpleGrid
        alignItems="center"
        columns={{base: 1, md: 2}}
        gridColumnGap={24}
        gridRowGap={6}
        justifyContent="center"
        paddingY={{base: 10, md: 12 }}
        paddingX={{base: 10, md: 2}}
      >
        <Image
          flex={1}
          height={{base: 360, md: 470}}
          marginTop={8}
          objectFit="contain"
          src={FOR_CLIENTS.img}
          width={{base: 380, md: '100%'}}
          position='relative'
          left='2rem'
          bottom='2rem'
          transform={{base: 'scale(1)', md: "scale(1.1)"}}
          display={{ base: 'none', md: "inherit" }}
         />
        <Stack alignItems="flex-start" flex={1} spacing={8}>
          <Stack spacing={6}>
            <Heading as="h2" textAlign={{base: 'center', md: 'inherit'}} width={{base: '100%', md:'75%'}} fontSize={{base: "3xl", md: "5xl"}} color={"green.400"}>
              {t(FOR_CLIENTS.title)}            
            </Heading>
            <Box>
              {formatTranslationText(t(FOR_CLIENTS.subtitle)).map((line: string) => {
              return <TranslationsFormatText line={line} key={line} fontSize={{base: "lg", md: "xl"}} />
              })}
            </Box>
            <Stack>
              {FOR_CLIENTS.bulletPoints.map((item, index) => {
                return <Flex key={index}>
                  <CheckIcon mr={5} width={6}/>
                  <Text fontSize={{base: "lg", md: "xl"}}>{t(item)}</Text>
                </Flex>
              })}
            </Stack>
            <Box>
              <Button 
                _hover={{ boxShadow: "lg" }}
                size="lg"
                color={"white"}
                fontWeight={400}
                backgroundColor={"green.400"}
              >
              {t(FOR_CLIENTS.button)}            
              </Button>
            </Box>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Content>
  );
};

export default MadeForClients;
