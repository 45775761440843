import React from "react";
import { Box, Stack, Text, Flex } from "@chakra-ui/core";

import { useTranslation } from "~/i18n/hooks";
import { Plan } from "~/types/plans";
import Heading from "~/ui/display/Heading";
import { formatTranslationText } from "~/utils/formatTranslations";
import TranslationsFormatText from "~/components/TranslationsFormatText";

interface Props {
  plan: Plan;
}

const PlanCard: React.FC<Props> = ({ plan }) => {
  const t = useTranslation();
  

  return (
    <Stack
      backgroundColor="white"
      borderRadius="lg"
      boxShadow="xl"
      padding={8}
      paddingTop={4}
      paddingBottom={0}
      spacing={{ base: 6, md: 4 }}
      height={{ base: "380px", md: "100%", lg: '370px' }}
      width={"360px"}
      marginRight='1rem'
      marginLeft='1rem'
      marginBottom={{base: '2rem', md: '0rem'}}
    >
      <Flex 
        alignItems='center' 
        justifyContent='center' 
        height='2.5rem' 
        color='white'  
        backgroundColor={plan.color} 
        borderRadius="0.25rem"
      >
        <Heading fontSize='1.1rem'>
        {t(plan.title)}
        </Heading>
      </Flex>
      <Stack spacing={0}>
        <Text fontSize="sm" fontWeight="bold" textTransform="uppercase">
          {t("plans.priceFrom")}
        </Text>
        <Heading color="primary.900" fontSize={{ base: "4xl", md: "5xl" }}>
          ${plan.price}*
        </Heading>
      </Stack>
      <Box>
        {formatTranslationText(t(plan.description)).map((line: string) => {
          return (
            <TranslationsFormatText
              line={line}
              key={line}
              color="gray.600"
              fontSize="md"
            />
          );
        })}
      </Box>
      <Text fontSize='16px' color='#808488'>*{t(plan.extraInfo)}</Text>
    </Stack>
  );
};

export default PlanCard;
