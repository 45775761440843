import React from "react";
import { Stack, Text } from "@chakra-ui/core";

import { useTranslation } from "~/i18n/hooks";
import { Benefit } from "~/types/benefits";
import Heading from "~/ui/display/Heading";
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  benefit: Benefit;
}

const BenefitCard: React.FC<Props> = ({ benefit }) => {
  const t = useTranslation();

  return (
    <Stack spacing={{ base: 2, md: 3 }} justify={"center"} align={"center"}>
      <LazyLoadImage effect='blur' height={145} src={benefit.image} alt={t(benefit.alt)} />
      <Heading marginTop={{base: '0.5rem', md: '0.7rem'}} fontSize="24px">
        {t(benefit.title)}
      </Heading>
      <Text fontSize={"lg"} textAlign="center">
        {t(benefit.description)}
      </Text>
    </Stack>
  );
};

export default BenefitCard;