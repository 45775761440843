import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/core";

import Content from "~/ui/structure/Content";
import Heading from "~/ui/display/Heading";
import { useTranslation } from "~/i18n/hooks";
import { FEATURES } from "~/constanst/home";
import FeatureCard from "./FeatureCard";

const Features: React.FC = () => {
      const t = useTranslation();

    return (
        <Content
            paddingX={6}
            paddingY={{ base: 5, md: 20 }}
            marginBottom={{base: 0, md: 20}}
        >
            <Box margin={"auto"} width={{md: "50%", base: "80%"}} mb={{md: "5rem", base: "2rem"}}>
                <Heading fontSize={{ base: "3xl", md: "2.6rem" }} color={"green.400"} textAlign="center">
                    {t("home.features.title")}
                </Heading>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, md: 10 }} my={8}>
                {FEATURES.map((feature, index) => {
                    return <FeatureCard feature={feature} key={index} />
                })}
            </SimpleGrid>
        </Content>
    );
};

export default Features;