import React from "react";
import { Text, SimpleGrid, Stack, Accordion, AccordionItem, AccordionHeader, AccordionIcon, AccordionPanel } from "@chakra-ui/core";

import Heading from "~/ui/display/Heading";
import Content from "~/ui/structure/Content";
import { useTranslation } from "~/i18n/hooks";
import { FAQS } from "~/constanst/home";

const FAQs: React.FC = () => {
  const t = useTranslation();

  return (
    <Content backgroundColor="white" paddingX={6} paddingY={{ base: 12, md: 20 }}>
      <Stack spacing={{ base: 10, md: 16 }}>
        <SimpleGrid
          alignItems="flex-end"
          columns={{ base: 1, md: 2 }}
          gridColumnGap={4}
          gridRowGap={4}
          justifyContent="space-between"
        >
          <Heading as="h3" fontSize={{ base: "lg", md: "2xl" }} maxWidth={480} color={"primary.500"}>
            {t("home.faqs.title")}
          </Heading>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gridGap={12}>
          {FAQS(t).map((faq) => (
            <Accordion allowMultiple key={faq.title}>
              <AccordionItem>
                <AccordionHeader _focus={{ border: "0px"}} justifyContent="space-between">
                  <Text fontSize="lg" fontWeight="bold">
                    {faq.title}
                  </Text>
                  <AccordionIcon />
                </AccordionHeader>
                <AccordionPanel pb={4}>
                  <Text>{faq.description}</Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
        </SimpleGrid>
      </Stack>
    </Content>
  );
};

export default FAQs;
