import React from "react";
import { Text, BoxProps } from "@chakra-ui/core";

interface Props extends BoxProps {
  line: string;
}

const TranslationsFormatText: React.FC<Props> = ({ line, ...props }) => {
  const isBold = line.startsWith("b?");
  const isUnderline = line.startsWith("u?");
  return (
    <Text
      key={line}
      as="span"
      fontWeight={isBold ? 600 : 400}
      textDecoration={isUnderline ? "underline" : "none"}
      {...props}
    >
      {line.replace("b?", "").replace("u?", "")}
    </Text>
  );
};

export default TranslationsFormatText;