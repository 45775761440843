import fs from "fs";

import React from "react";
import {Stack} from "@chakra-ui/core";
import {GetStaticProps} from "next";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import Head from "next/head";
import {i18n} from "next-i18next";

import Navbar from "~/sections/Navbar";
import HomeHero from "~/sections/Home/HomeHero";
import MadeForClients from "~/sections/Home/MadeForClients";
import Testimonials from "~/sections/Home/Testimonials";
import CreateAccountCTA from "~/components/CreateAccountCTA";
import Footer from "~/sections/Footer";
import StickyButtons from "~/sections/StickyButtons";
import {Tenant} from "~/tenant/types";
// import api from "~/tenant/api";
import {buildSitemap} from "~/utils/sitemap";
import {useTranslation} from "~/i18n/hooks";
import FAQs from "~/sections/Home/FAQs";
import PlansPrices from "~/sections/Home/PlansPrices";
import Features from "~/sections/Home/Features";
import Benefits from "~/sections/Home/Benefits";

interface Props {
  tenants: Tenant[];
}

const LandingRoute: React.FC<Props> = ({tenants}) => {
  const t = useTranslation();

  return (
    <Stack height="100%" minHeight="100vh" spacing={0}>
      <Head>
        <title>{t("home.title")}</title>
        <meta content={t("home.description")} name="description" />
        <meta content={t("home.keywords")} name="keywords" />
        <link href={process.env.URL} hrefLang="x-default" rel="alternate" />
        <link href={process.env.URL} hrefLang="es" rel="alternate" />
        <link href={`${process.env.URL}/pt/`} hrefLang="pt" rel="alternate" />
        <link href={`${process.env.URL}/en/`} hrefLang="en" rel="alternate" />
        <meta content={t("home.title")} property="og:title" />
        <meta content={t("home.description")} property="og:description" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="og:image:url"
        />
        <meta content={t("home.title")} name="twitter:title" />
        <meta content={t("home.description")} name="twitter:description" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="twitter:image"
        />
      </Head>
      <Navbar backgroundColor="green.400" color="white" />
      <HomeHero />
      <Benefits />
      <MadeForClients />
      <Features />
      <PlansPrices />
      <Testimonials tenants={tenants} />
      <CreateAccountCTA 
        display={{base: "none", md: "inherit"}}
        title={"createAccount.titleHome"}
        titleColor={"green.400"} 
        subtitle={"createAccount.subtitleHome"} 
        btnText={"createAccount.actionHome"} 
        svg1={"/assets/create-account-cta-0.svg"} 
        svg2={"/assets/create-account-cta-1.svg"} 
      />
      <FAQs />
      <Footer />
      <StickyButtons />
    </Stack>
  );
};

export const getStaticProps: GetStaticProps = async ({locale}) => {
  // Get relevant stores from db
  // const relevant: Tenant[] = await api.relevant();
  // fails to retrieve relevant stores so I commented it
  const relevant = [];

  // Get sitemap stores from db
  // const sitemap: Tenant[] = await api.sitemap();
  // fails to retrieve sitemap stores so I commented it
  const sitemap = [];

  // Build sitemap
  fs.writeFileSync("public/sitemap.xml", buildSitemap(sitemap));

  // Return stores so we can build a directory
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      tenants: relevant,
    },
    // Revalidate every 24hs
    revalidate: 3600 * 24,
  };
};

export default LandingRoute;
