import React from "react";
import { Box, Button, Flex } from "@chakra-ui/core";

import Content from "~/ui/structure/Content";
import PlanCard from "./PlanCard";
import { PLANS } from "~/constanst/plans";
import Heading from "~/ui/display/Heading";
import { useTranslation } from "~/i18n/hooks";
import TranslationsFormatText from "~/components/TranslationsFormatText";
import { formatTranslationText } from "~/utils/formatTranslations";
import UnstyledLink from "@pency/components/controls/Link";
import { getLocale } from "~/i18n/selectors";

const PlansPrices: React.FC = () => {
  const t = useTranslation();
  const locale = getLocale();

  return (
    <Content
      paddingX={6}
      paddingY={{ base: 8, md: 20 }}
      paddingTop={{base: 5, md: 16}}
      backgroundColor={"green.400"}
    >
      <Heading fontSize={{base: "3xl", md: "3.375rem"}} color={"white"} textAlign="center">
        {t("home.plans.title")}
      </Heading>
      <Box textAlign="center" mt={4} mb={{base: '2.5rem', md:'5.8rem'}}>
          {formatTranslationText(t("home.plans.subtitle")).map((line: string) => {
            return <TranslationsFormatText line={line} key={line} color="white" fontSize={{base: "md", md: "1.875rem"}} />
          })}
      </Box>
      <Flex 
        align-self="start" 
        justifyContent='space-between'
        my={{base: 4, md: 10}}
        alignItems={{base: "center", md: 'inherit'}}
        flexDirection={{base: "column", md: 'row'}}
      >
        {PLANS.map((plan, index) => {
          return <PlanCard plan={plan} key={index} />
        })}
      </Flex>
      <Box textAlign={"center"} mt={{base: "0rem", md: "6rem"}}>
        <UnstyledLink href={`${locale ? `/${locale}` : ""}/${t("menu.plans.url")}`}>
              <Button
                _hover={{backgroundColor: "transparent", color: "white"}}
                backgroundColor="transparent"
                color={"white"}
                display='inherit'
                _focus={{ border: "0px"}}
                variant="outline"
                size="lg"
              >
                {t("home.plans.compare")}
              </Button>
        </UnstyledLink>
      </Box>
    </Content>
  );
};

export default PlansPrices;
