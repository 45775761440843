import React from "react";
import {Stack} from "@chakra-ui/core";
import Button from "@pency/components/controls/Button";
import Link from "@pency/components/controls/Link";

import Content from "~/ui/structure/Content";
import {getLocale} from "~/i18n/selectors";
import {useTranslation} from "~/i18n/hooks";

const StickyButtons: React.FC = () => {
  const locale = getLocale();
  const t = useTranslation();

  return (
    <Content
      backgroundImage="url('/assets/bg-cta.png')"
      backgroundPosition="bottom"
      backgroundRepeat="repeat-x"
      bgSize="100% 80px"
      bottom={0}
      display={{base: "inherit", md: "none"}}
      position="sticky"
    >
      <Stack isInline alignItems="center" justifyContent="center" margin={4} spacing={2}>
        <Link flex={1} href={`${process.env.STORE_URL}/${t("menu.demoStore")}`}>
          <Button
            backgroundColor="primary.100"
            borderRadius={4}
            color="primary.700"
            size="lg"
            width="100%"
          >
            {t("home.hero.actionSecondary")}
          </Button>
        </Link>
        <Link flex={1} href={`${process.env.SIGNUP_URL}/${locale}`}>
          <Button borderRadius={4} size="lg" variantColor="primary" width="100%">
            {t("menu.signUp")}
          </Button>
        </Link>
      </Stack>
    </Content>
  );
};

export default StickyButtons;
