export const HERO = {
    title: "plans.hero.title",
    subtitle: "plans.hero.subtitle",
    image: "/assets/newPlansHero.png",
    imageAlt: "plans.hero.imageAlt"
};

export const PLANS = [
    {
        title: "plans.PLAN_FREE.title",
        label: "plans.PLAN_FREE.label",
        description: "plans.PLAN_FREE.description",
        price: 1350,
        color: "#54AE70",
        extraInfo: "plans.PLAN_FREE.extraInfo"
    },
    {
        title: "plans.PLAN_COMMERCIAL.title",
        label: "plans.PLAN_COMMERCIAL.label",
        description: "plans.PLAN_COMMERCIAL.description",
        price: 4200,
        color: "#FF8080",
        extraInfo: "plans.PLAN_COMMERCIAL.extraInfo"
    },
    {
        title: "plans.PLAN_PREMIUM.title",
        label: "plans.PLAN_PREMIUM.label",
        description: "plans.PLAN_PREMIUM.description",
        price: 7875,
        color: "#9563FF",
        extraInfo: "plans.PLAN_PREMIUM.extraInfo"
    }
];

export const FEATURES_BY_PLAN = [
  {
    title: "plans.features.catalog",
    features: [
      {
        title: "plans.features.productsQty",
        free: 50,
        comercial: 100,
        premium: "plans.features.limitless"
      },
      {
        title: "plans.features.imgQty",
        free: 1,
        comercial: 4,
        premium: 4
      },
      {
        title: "plans.features.updateChanges",
        free: "plans.features.realTime",
        comercial: "plans.features.realTime",
        premium: "plans.features.realTime"
      },
      {
        title: "plans.features.promoPrices",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.variants",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.productsImporter",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.stockManager",
        free: false,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.pricesEditor",
        free: false,
        comercial: true,
        premium: true
      },
    ]
  },
  {
    title: "plans.features.store",
    features: [
      {
        title: "plans.features.presonalization",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.currencyConfig",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.visitsReports",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.fbShop",
        free: false,
        comercial: false,
        premium: true
      },
      {
        title: "plans.features.instaShop",
        free: false,
        comercial: false,
        premium: true
      },
      {
        title: "plans.features.visitsQty",
        free: "plans.features.limitless",
        comercial: "plans.features.limitless",
        premium: "plans.features.limitless"
      },
      {
        title: "plans.features.support",
        free: "plans.features.whatsApp",
        comercial: "plans.features.whatsApp",
        premium: "plans.features.whatsApp"
      },
    ]
  },
  {
    title: "plans.features.orders",
    features: [
      {
        title: "plans.features.checkout",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.wpCheckout",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.ordersDashboard",
        free: false,
        comercial: false,
        premium: true
      },
    ]
  },
  {
    title: "plans.features.payment",
    features: [
      {
        title: "plans.features.freeChoose",
        free: true,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.mpCheckout",
        free: true,
        comercial: true,
        premium: true
      },
    ]
  },
  {
    title: "plans.features.additionals",
    features: [
      {
        title: "plans.features.fbPixel",
        free: false,
        comercial: true,
        premium: true
      },
      {
        title: "plans.features.analytics",
        free: false,
        comercial: false,
        premium: true
      },
    ]
  }
];