import React from "react";
import { SimpleGrid } from "@chakra-ui/core";

import Content from "~/ui/structure/Content";
import { BENEFITS } from "~/constanst/home";
import BenefitCard from "./BenefitCard";

const Benefits: React.FC = () => {
    return (
        <Content marginY={{ base: 0, md: 12 }} paddingX={10} paddingY={6}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, md: 10 }}>
                {BENEFITS.map((benefit, index) => {
                    return <BenefitCard benefit={benefit} key={index} />
                })}
            </SimpleGrid>
        </Content>
    );
};

export default Benefits;